<template>
  <div id="collapse-C" class="collapse show">
    <div ref="specialties-container" class="card-body position-relative">
      <h5>Professional Specialties</h5>
      <div class="row">
        <div class="col">
          <ul class="list-group">
            <li
              v-for="specialty in specialties"
              :key="specialty.id"
              class="list-group-item"
            >
              <input
                :id="`included-${specialty.id}`"
                v-model="specialty.included"
                type="checkbox"
                @change="updateSpecialty(specialty)"
              />
              {{ specialty.name }}
              {{ specialty.description ? ' - ' + specialty.description : '' }}
            </li>
          </ul>
          <tbl-page-seln
            v-model="pgNum"
            class="mt-3"
            :total-pages="totalPages"
            @updated="refresh"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref, onMounted } from 'vue';
import TblPageSeln from '../shared/components/TblPageSeln.vue';

export default {
  components: {
    TblPageSeln,
  },
  setup() {
    const profApi = inject('api').profApi();
    const loading = inject('$loading');

    onMounted(() => {
      refresh();
    });

    const specialties = ref([]);
    const pgNum = ref(1);
    const pgSz = ref(10);
    const totalPages = ref(0);
    const specialtiesContainer = ref(null);

    const refresh = async () => {
      const loader = loading.show({
        container: specialtiesContainer.value,
        isFullPage: false,
      });
      const response = await profApi.getSpecialties(
        pgSz.value,
        pgNum.value - 1
      );
      specialties.value = response.data.data;
      totalPages.value = response.data.totalPages;
      loader.hide();
    };

    const updateSpecialty = async (specialty) => {
      const loader = loading.show({
        container: specialtiesContainer.value,
        isFullPage: false,
      });
      await profApi.updateSpecialty(specialty.id, specialty.included);
      loader.hide();
    };

    return {
      specialties,
      'specialties-container': specialtiesContainer,
      refresh,
      pgNum,
      pgSz,
      totalPages,
      updateSpecialty,
    };
  },
};
</script>
