<template>
  <div class="container">
    <!-- Page content here -->
    <div ref="profile-container" class="row position-relative">
      <!-- tabbed nav for desktop hidden on mobile view -->
      <div class="col responsive-tabs">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ active: currTab == 'my-profile' }"
              style="cursor: pointer"
              @click="setTab('my-profile')"
              @clean="isDirty = false"
              @dirty="isDirty = true"
              >My Profile</a
            >
          </li>
          <li v-if="$store.getters.userType == 'professional'" class="nav-item">
            <a
              class="nav-link"
              :class="{ active: currTab == 'my-specialties' }"
              style="cursor: pointer"
              @click="setTab('my-specialties')"
              >Specialties</a
            >
          </li>
        </ul>

        <!-- Accordion items - headers hidden on desktop view-->
        <div id="content" class="tab-content mb-4">
          <!-- tab one My Profile -->
          <div
            id="pane-A"
            class="card tab-pane fade"
            :class="{ 'active show': currTab == 'my-profile' }"
          >
            <div class="card-header">
              <h5 class="mb-0">
                <a
                  :class="{ collapsed: currTab != 'my-profile' }"
                  style="cursor: pointer"
                  @click="setTab('my-profile')"
                  >My Profile</a
                >
              </h5>
            </div>
            <my-profile
              v-if="currTab == 'my-profile'"
              :is-dirty="isDirty"
              @dirty="isDirty = true"
              @clean="isDirty = false"
            />
          </div>

          <!-- tab three Other -->
          <div
            v-if="$store.getters.userType == 'professional'"
            id="pane-C"
            class="card tab-pane fade"
            :class="{ 'active show': currTab == 'my-specialties' }"
          >
            <div class="card-header">
              <h5 class="mb-0">
                <a
                  :class="{ collapsed: currTab != 'my-specialties' }"
                  style="cursor: pointer"
                  @click="setTab('my-specialties')"
                  >Specialties</a
                >
              </h5>
            </div>
            <my-specialties v-if="currTab == 'my-specialties'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeMount, onBeforeUnmount } from 'vue';
import { useRouter, useRoute, onBeforeRouteLeave } from 'vue-router';
import { useStore } from 'vuex';
import MyProfile from './MyProfile.vue';
import MySpecialties from './MySpecialties.vue';

export default {
  components: {
    MyProfile,
    MySpecialties,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    onMounted(() => {
      if (route.query && route.query.tab) {
        currTab.value = route.query.tab;
      } else {
        currTab.value = 'my-profile';
      }
    });

    const currTab = ref('my-profile');
    const setTab = async (tabName) => {
      if (isDirty.value) {
        if (
          !window.confirm(
            'Are you sure you want to leave? You have unsaved changes.'
          )
        ) {
          return;
        }
      }
      isDirty.value = false;

      currTab.value = tabName;
      router.replace({ query: { tab: tabName } });
    };

    const isDirty = ref(false);

    // listen for attempts to leave app
    onBeforeMount(() => {
      window.addEventListener('beforeunload', preventNav);
    });

    // prevent memory leak
    onBeforeUnmount(() => {
      window.removeEventListener('beforeunload', preventNav);
    });

    // checks for unsaved changes before leaving app
    const preventNav = (event) => {
      if (!isDirty.value) return;
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    const store = useStore();
    // checks for unsaved changes before intra-app routing
    onBeforeRouteLeave((to, from, next) => {
      if (isDirty.value && store.getters.isAuthed) {
        if (
          !window.confirm(
            'Are you sure you want to leave? You have unsaved changes.'
          )
        ) {
          return;
        }
      }
      isDirty.value = false;
      next();
    });

    return {
      currTab,
      setTab,
      isDirty,
    };
  },
};
</script>
