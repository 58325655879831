<template>
  <div id="collapse-A" class="collapse show">
    <div class="card-body">
      <div class="row align-items-start">
        <div class="col-lg-6 col-xl-5 mb-4">
          <div class="row g-2">
            <div class="col-12 d-flex align-items-end">
              <img :src="profilePicSrc(patientId)" class="w-25" alt="..." />
              <div class="ms-3">
                <!-- <button class="btn btn-sm btn-outline-primary">
                          Upload Photo
                        </button> -->
                <profile-photo-upload />
                <button id="remove-pic-btn" class="btn btn-sm btn-outline-danger" @click="removePic()">
                  remove
                </button>
                <small class="d-block lh-sm mt-2 text-muted">
                  Allowed Formats: JPG, GIF, PNG. <br />Max dimensions: 250(w) x
                  500(h) pixels @ 200k
                </small>
              </div>
            </div>
            <div class="col-md-12">
              <small>Email: {{ profile.email }}</small>
            </div>
            <div class="col-12" v-if="!is_admin_user">
              <div class="form-floating">
                <input id="phone" v-model="profile.phone" type="tel" class="form-control"
                  :class="{ 'is-invalid': phoneIssue }" placeholder="Your Phone Number" :disabled="is_admin_user"
                  @input="phoneInput" />
                <label for="phone">Phone Number</label>
                <div v-if="phoneIssue" class="m-1 small text-danger">
                  {{ phoneIssue }}
                </div>
              </div>
            </div>
            <div class="col-12" v-if="!is_admin_user">
              <div class="form-floating">
                <input id="address" v-model="profile.address" class="form-control" :class="{ 'is-invalid': addressIssue }"
                  placeholder="Address" :disabled="is_admin_user" @input="addressInput" />
                <label for="address">Address</label>
                <div v-if="addressIssue" class="m-1 small text-danger">
                  {{ addressIssue }}
                </div>
              </div>
            </div>
            <div class="col-md-8" v-if="!is_admin_user">
              <div class="form-floating">
                <input id="city" v-model="profile.city" class="form-control" :class="{ 'is-invalid': cityIssue }"
                  placeholder="City" :disabled="is_admin_user" @input="cityInput" />
                <label for="address">City</label>
                <div v-if="cityIssue" class="m-1 small text-danger">
                  {{ cityIssue }}
                </div>
              </div>
            </div>
            <div class="col-md-4" v-if="!is_admin_user">
              <div class="form-floating">
                <select id="floatingSelect" v-model="profile.stateCode" class="form-select"
                  aria-label="Floating label select example" :disabled="is_admin_user" @input="$emit('dirty')">
                  <option :value="null">Select your state</option>
                  <option value="OH">Ohio</option>
                </select>
                <label for="floatingSelect">State</label>
              </div>
            </div>
            <div class="col-12" v-if="!is_admin_user">
              <div class="form-floating">
                <input id="zipcode" v-model="profile.postalCode" type="tel" class="form-control"
                  :class="{ 'is-invalid': postalCodeIssue }" placeholder="Your zip code " :disabled="is_admin_user"
                  @input="postalCodeInput" />
                <label for="zipcode">Zip Code</label>
                <div v-if="postalCodeIssue" class="m-1 small text-danger">
                  {{ postalCodeIssue }}
                </div>
              </div>
            </div>
            <div class="col-12 " v-if="!is_admin_user">
              <div class="form-floating">
                <select id="floatingSelect" :disabled="is_admin_user" v-model="profile.timezoneId" class="form-select"
                  aria-label="Floating label select example" @input="$emit('dirty')">
                  <option v-for="timezone in timezones" :key="timezone.id" :value="timezone.id">
                    {{ timezone.name }}
                  </option>
                </select>
                <label for="floatingSelect">Preferred Time Zone</label>
              </div>
            </div>
          </div>
        </div>

        <div class="col mb-4">
          <div class="row g-2">
            <div class="col-md-12" v-if="!is_admin_user">
              <div class="form-floating">
                <input id="prefix" v-model="profile.prefix" type="text" class="form-control"
                  :class="{ 'is-invalid': prefixIssue }" placeholder="Prefix" :disabled="is_admin_user"
                  @input="prefixInput" />
                <label for="prefix">Prefix</label>
                <div v-if="prefixIssue" class="m-1 small text-danger">
                  {{ prefixIssue }}
                </div>
              </div>
            </div>
            <div class="col-md">
              <div class="form-floating">
                <input id="fName" v-model.trim="profile.firstName" type="text" class="form-control"
                  :class="{ 'is-invalid': firstNameIssue }" placeholder="First Name" :disabled="is_admin_user"
                  @input="firstNameInput" />
                <label for="fName">First Name*</label>
                <div v-if="firstNameIssue" class="m-1 small text-danger">
                  {{ firstNameIssue }}
                </div>
              </div>
            </div>
            <div class="col-md-2" v-if="!is_admin_user">
              <div class="form-floating">
                <input id="mName" v-model.trim="profile.middleInitial" type="text" class="form-control"
                  placeholder="First Name" maxLength="1" :disabled="is_admin_user" style="text-transform: uppercase"
                  @input="$emit('dirty')" />
                <label for="mName">Middle</label>
              </div>
            </div>
            <div class="col-md">
              <div class="form-floating">
                <input id="lName" v-model.trim="profile.lastName" type="text" class="form-control"
                  :class="{ 'is-invalid': lastNameIssue }" placeholder="Last Name" :disabled="is_admin_user"
                  @input="lastNameInput" />
                <label for="lName">Last Name*</label>
                <div v-if="lastNameIssue" class="m-1 small text-danger">
                  {{ lastNameIssue }}
                </div>
              </div>
            </div>
            <div v-if="userType == 'professional'" class="col-md-12 mb-3">
              <div class="form-floating">
                <input id="Cert" v-model="profile.certifications" type="text" class="form-control"
                  :class="{ 'is-invalid': certificationsIssue }" placeholder="Letters to add after name"
                  :disabled="is_admin_user" @input="certificationsInput" />
                <label for="Cert">Letters to add after your name</label>
                <div v-if="certificationsIssue" class="m-1 small text-danger">
                  {{ certificationsIssue }}
                </div>
              </div>
            </div>
            <div v-if="userType == 'professional'" class="col-md-12 mb-3">
              <label for="exampleFormControlTextarea1" class="form-label">My Bio</label>
              <textarea id="bio" v-model="profile.biography" class="form-control" :disabled="is_admin_user" rows="10"
                @input="$emit('dirty')">
              </textarea>
            </div>
            <div class="col-12">
              <div v-if="userType == 'professional'" class="form-check">
                <input id="can-supervise" v-model="profile.canSupervise" class="form-check-input" type="checkbox"
                  :disabled="is_admin_user" @change="$emit('dirty')" />
                <label class="form-check-label" for="flexCheckChecked">
                  I am willing to supervise
                </label>
              </div>
              <div class="form-check">
                <input id="enable-notifs" v-model="profile.enableEmailNotifications" class="form-check-input"
                  type="checkbox" :disabled="is_admin_user" @change="$emit('dirty')" />
                <label class="form-check-label" for="flexCheckChecked">
                  Enable email notifications
                </label>
              </div>
            </div>
          </div>
          <div v-if="userType == 'patient'" class="row">
            <div class="col pt-4">
              <h2 class="h4 mb-3">My Emergency Contacts</h2>
              <h3 class="h5">Primary Care Doctor</h3>
              <div class="row row-cols-1 row-cols-md-2 row-cols-xl-4 g-2 mb-4">
                <div class="col">
                  <div class="form-floating">
                    <input id="fName" v-model="profile.doctorFirstName" type="text" class="form-control"
                      :class="{ 'is-invalid': doctorFirstNameIssue }" placeholder="First Name"
                      @input="doctorFirstNameInput" />
                    <label for="fName">First Name</label>
                    <div v-if="doctorFirstNameIssue" class="m-1 small text-danger">
                      {{ doctorFirstNameIssue }}
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-floating">
                    <input id="lName" v-model="profile.doctorLastName" type="text" class="form-control"
                      :class="{ 'is-invalid': doctorLastNameIssue }" placeholder="Last Name"
                      @input="doctorLastNameInput" />
                    <label for="lName">Last Name</label>
                    <div v-if="doctorLastNameIssue" class="m-1 small text-danger">
                      {{ doctorLastNameIssue }}
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-floating">
                    <input id="email" v-model="profile.doctorEmail" type="email" class="form-control"
                      :class="{ 'is-invalid': doctorEmailIssue }" placeholder="Email address" @input="doctorEmailInput" />
                    <label for="email">Email Address</label>
                    <div v-if="doctorEmailIssue" class="m-1 small text-danger">
                      {{ doctorEmailIssue }}
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-floating">
                    <input id="phone" v-model="profile.doctorPhone" type="phone" class="form-control"
                      :class="{ 'is-invalid': doctorPhoneIssue }" placeholder="Phone Number" @input="doctorPhoneInput" />
                    <label for="email">Phone</label>
                    <div v-if="doctorPhoneIssue" class="m-1 small text-danger">
                      {{ doctorPhoneIssue }}
                    </div>
                  </div>
                </div>
              </div>
              <h3 class="h5">Support Contact #1</h3>
              <div class="row row-cols-1 row-cols-md-2 row-cols-xl-4 g-2 mb-4">
                <div class="col">
                  <div class="form-floating">
                    <input id="fName" v-model="profile.support1FirstName" type="text" class="form-control"
                      :class="{ 'is-invalid': support1FirstNameIssue }" placeholder="First Name"
                      @input="support1FirstNameInput" />
                    <label for="fName">First Name</label>
                    <div v-if="support1FirstNameIssue" class="m-1 small text-danger">
                      {{ support1FirstNameIssue }}
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-floating">
                    <input id="lName" v-model="profile.support1LastName" :class="{ 'is-invalid': support1LastNameIssue }"
                      type="text" class="form-control" placeholder="Last Name" @input="support1LastNameInput" />
                    <label for="lName">Last Name</label>
                    <div v-if="support1LastNameIssue" class="m-1 small text-danger">
                      {{ support1LastNameIssue }}
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-floating">
                    <input id="email" v-model="profile.support1Email" :class="{ 'is-invalid': support1EmailIssue }"
                      type="email" class="form-control" placeholder="Email address" @input="support1EmailInput" />
                    <label for="email">Email Address</label>
                    <div v-if="support1EmailIssue" class="m-1 small text-danger">
                      {{ support1EmailIssue }}
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-floating">
                    <input id="phone" v-model="profile.support1Phone" :class="{ 'is-invalid': support1PhoneIssue }"
                      type="phone" class="form-control" placeholder="Phone Number" @input="support1PhoneInput" />
                    <label for="email">Phone</label>
                    <div v-if="support1PhoneIssue" class="m-1 small text-danger">
                      {{ support1PhoneIssue }}
                    </div>
                  </div>
                </div>
              </div>

              <h3 class="h5">Support Contact #2</h3>
              <div class="row row-cols-1 row-cols-md-2 row-cols-xl-4 g-2 mb-4">
                <div class="col">
                  <div class="form-floating">
                    <input id="fName" v-model="profile.support2FirstName"
                      :class="{ 'is-invalid': support2FirstNameIssue }" type="text" class="form-control"
                      placeholder="First Name" @input="support2FirstNameInput" />
                    <label for="fName">First Name</label>
                    <div v-if="support2FirstNameIssue" class="m-1 small text-danger">
                      {{ support2FirstNameIssue }}
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-floating">
                    <input id="lName" v-model="profile.support2LastName" type="text"
                      :class="{ 'is-invalid': support2LastNameIssue }" class="form-control" placeholder="Last Name"
                      @input="support2LastNameInput" />
                    <label for="lName">Last Name</label>
                    <div v-if="support2LastNameIssue" class="m-1 small text-danger">
                      {{ support2LastNameIssue }}
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-floating">
                    <input id="email" v-model="profile.support2Email" :class="{ 'is-invalid': support2EmailIssue }"
                      type="email" class="form-control" placeholder="Email address" @input="support2EmailInput" />
                    <label for="email">Email Address</label>
                    <div v-if="support2EmailIssue" class="m-1 small text-danger">
                      {{ support2EmailIssue }}
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-floating">
                    <input id="phone" v-model="profile.support2Phone" :class="{ 'is-invalid': support2PhoneIssue }"
                      type="phone" class="form-control" placeholder="Phone Number" @input="support2PhoneInput" />
                    <label for="email">Phone</label>
                    <div v-if="support2PhoneIssue" class="m-1 small text-danger">
                      {{ support2PhoneIssue }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 border-top pt-3">
          <div class="d-grid gap-2 d-md-flex justify-content-md-end">
            <button id="save-changes-btn" :disabled="!isDirty || is_admin_user" class="btn btn-primary me-md-2"
              type="button" @click="saveChanges">
              Save Changes
            </button>
            <button id="cancel-changes-btn" :disabled="!isDirty || is_admin_user" class="btn btn-outline-secondary"
              type="button" @click="refresh">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import ProfilePhotoUpload from '../shared/components/ProfilePhotoUpload.vue';

export default {
  components: {
    ProfilePhotoUpload,
  },
  props: {
    isDirty: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['clean', 'dirty'],
  setup(props, context) {
    const api = inject('api');
    const loading = inject('$loading');
    const router = useRouter();

    const generalApi = api.generalDataApi();
    const userApi = api.userApi();

    const profileContainer = ref(null);

    const profile = ref({});
    const userType = ref(null);
    const timezones = ref([]);
    const originalEmail = ref('');

    const store = useStore();
    const is_admin_user = computed(() => {
      return store.getters.userType === 'administrator';
    });

    const patientId = ref('');
    const profilePicSrc = (pid) => {
      return `${process.env.VUE_APP_SERVER_URL}/api/user/pic/${pid}`;
    };

    const refresh = async () => {
      const loader = loading.show({
        container: profileContainer.value,
        isFullPage: false,
      });

      const response = await userApi.getProfileInfo(userType.value);
      profile.value = response.data;
      originalEmail.value = response.data.email;
      context.emit('clean');
      validateFields();
      loader.hide();
    };

    const emailIssue = ref('');
    const firstNameIssue = ref('');
    const lastNameIssue = ref('');
    const prefixIssue = ref('');
    const phoneIssue = ref('');
    const postalCodeIssue = ref('');
    const certificationsIssue = ref('');
    const addressIssue = ref('');
    const cityIssue = ref('');

    const doctorFirstNameIssue = ref('');
    const doctorLastNameIssue = ref('');
    const doctorEmailIssue = ref('');
    const doctorPhoneIssue = ref('');

    const support1FirstNameIssue = ref('');
    const support1LastNameIssue = ref('');
    const support1EmailIssue = ref('');
    const support1PhoneIssue = ref('');

    const support2FirstNameIssue = ref('');
    const support2LastNameIssue = ref('');
    const support2EmailIssue = ref('');
    const support2PhoneIssue = ref('');

    const validateFields = () => {
      let isValid = true;
      firstNameIssue.value = '';
      lastNameIssue.value = '';
      emailIssue.value = '';
      prefixIssue.value = '';
      phoneIssue.value = '';
      postalCodeIssue.value = '';
      certificationsIssue.value = '';
      addressIssue.value = '';
      cityIssue.value = '';

      doctorFirstNameIssue.value = '';
      doctorLastNameIssue.value = '';
      doctorEmailIssue.value = '';
      doctorPhoneIssue.value = '';

      support1FirstNameIssue.value = '';
      support1LastNameIssue.value = '';
      support1EmailIssue.value = '';
      support1PhoneIssue.value = '';

      support2FirstNameIssue.value = '';
      support2LastNameIssue.value = '';
      support2EmailIssue.value = '';
      support2PhoneIssue.value = '';

      if (!profile.value.firstName) {
        firstNameIssue.value = 'First name is required';
        isValid = false;
      }
      if (profile.value.firstName && profile.value.firstName.length > 80) {
        firstNameIssue.value = 'First name is too long';
        isValid = false;
      }
      if (!profile.value.lastName) {
        lastNameIssue.value = 'Last name is required';
        isValid = false;
      }
      if (profile.value.lastName && profile.value.lastName.length > 80) {
        lastNameIssue.value = 'Last name is too long';
        isValid = false;
      }
      if (
        !profile.value.email ||
        profile.value.email.length < 5 ||
        !/@/.test(profile.value.email)
      ) {
        emailIssue.value = 'Please enter a valid email';
        isValid = false;
      }
      if (profile.value.email && profile.value.email.length > 50) {
        emailIssue.value = 'Email is too long';
        isValid = false;
      }
      if (profile.value.prefix && profile.value.prefix.length > 10) {
        prefixIssue.value = 'Prefix is too long';
        isValid = false;
      }
      if (profile.value.phone && profile.value.phone.length > 16) {
        phoneIssue.value = 'Phone number is too long';
        isValid = false;
      }
      if (profile.value.postalCode && profile.value.postalCode.length > 15) {
        postalCodeIssue.value = 'Zip code is too long';
        isValid = false;
      }
      if (
        profile.value.certifications &&
        profile.value.certifications.length > 60
      ) {
        certificationsIssue.value = 'Too many characters used';
        isValid = false;
      }
      if (profile.value.address && profile.value.address.length > 120) {
        addressIssue.value = 'Address is too long';
        isValid = false;
      }
      if (profile.value.city && profile.value.city.length > 60) {
        cityIssue.value = 'City is too long';
        isValid = false;
      }

      if (
        profile.value.doctorFirstName &&
        profile.value.doctorFirstName.length > 80
      ) {
        doctorFirstNameIssue.value = 'First name is too long';
        isValid = false;
      }
      if (
        profile.value.support1FirstName &&
        profile.value.support1FirstName.length > 80
      ) {
        support1FirstNameIssue.value = 'First name is too long';
        isValid = false;
      }
      if (
        profile.value.support2FirstName &&
        profile.value.support2FirstName.length > 80
      ) {
        support2FirstNameIssue.value = 'First name is too long';
        isValid = false;
      }

      if (
        profile.value.doctorLastName &&
        profile.value.doctorLastName.length > 80
      ) {
        doctorLastNameIssue.value = 'Last name is too long';
        isValid = false;
      }
      if (
        profile.value.support1LastName &&
        profile.value.support1LastName.length > 80
      ) {
        support1LastNameIssue.value = 'Last name is too long';
        isValid = false;
      }
      if (
        profile.value.support2LastName &&
        profile.value.support2LastName.length > 80
      ) {
        support2LastNameIssue.value = 'Last name is too long';
        isValid = false;
      }

      if (profile.value.doctorPhone && profile.value.doctorPhone.length > 16) {
        doctorPhoneIssue.value = 'Phone number is too long';
        isValid = false;
      }
      if (
        profile.value.support1Phone &&
        profile.value.support1Phone.length > 16
      ) {
        support1PhoneIssue.value = 'Phone number is too long';
        isValid = false;
      }
      if (
        profile.value.support2Phone &&
        profile.value.support2Phone.length > 16
      ) {
        support2PhoneIssue.value = 'Phone number is too long';
        isValid = false;
      }

      if (
        profile.value.doctorEmail &&
        (profile.value.doctorEmail.length < 5 ||
          !/@/.test(profile.value.doctorEmail))
      ) {
        doctorEmailIssue.value = 'Please enter a valid email';
        isValid = false;
      }
      if (profile.value.doctorEmail && profile.value.doctorEmail.length > 50) {
        doctorEmailIssue.value = 'Email is too long';
        isValid = false;
      }

      if (
        profile.value.support1Email &&
        (profile.value.support1Email.length < 5 ||
          !/@/.test(profile.value.support1Email))
      ) {
        support1EmailIssue.value = 'Please enter a valid email';
        isValid = false;
      }
      if (
        profile.value.support1Email &&
        profile.value.support1Email.length > 50
      ) {
        support1EmailIssue.value = 'Email is too long';
        isValid = false;
      }

      if (
        profile.value.support2Email &&
        (profile.value.support2Email.length < 5 ||
          !/@/.test(profile.value.support2Email))
      ) {
        support2EmailIssue.value = 'Please enter a valid email';
        isValid = false;
      }
      if (
        profile.value.support2Email &&
        profile.value.support2Email.length > 50
      ) {
        support2EmailIssue.value = 'Email is too long';
        isValid = false;
      }

      return isValid;
    };

    const emailInput = () => {
      context.emit('dirty');
      emailIssue.value = '';
    };

    const firstNameInput = () => {
      context.emit('dirty');
      firstNameIssue.value = '';
    };

    const lastNameInput = () => {
      context.emit('dirty');
      lastNameIssue.value = '';
    };

    const prefixInput = () => {
      context.emit('dirty');
      prefixIssue.value = '';
    };

    const phoneInput = () => {
      context.emit('dirty');
      phoneIssue.value = '';
    };

    const postalCodeInput = () => {
      context.emit('dirty');
      postalCodeIssue.value = '';
    };

    const certificationsInput = () => {
      context.emit('dirty');
      certificationsIssue.value = '';
    };

    const addressInput = () => {
      context.emit('dirty');
      addressIssue.value = '';
    };

    const cityInput = () => {
      context.emit('dirty');
      cityIssue.value = '';
    };

    const doctorFirstNameInput = () => {
      context.emit('dirty');
      doctorFirstNameIssue.value = '';
    };
    const doctorLastNameInput = () => {
      context.emit('dirty');
      doctorLastNameIssue.value = '';
    };
    const doctorPhoneInput = () => {
      context.emit('dirty');
      doctorPhoneIssue.value = '';
    };
    const doctorEmailInput = () => {
      context.emit('dirty');
      doctorEmailIssue.value = '';
    };

    const support1FirstNameInput = () => {
      context.emit('dirty');
      support1FirstNameIssue.value = '';
    };
    const support1LastNameInput = () => {
      context.emit('dirty');
      support1LastNameIssue.value = '';
    };
    const support1PhoneInput = () => {
      context.emit('dirty');
      support1PhoneIssue.value = '';
    };
    const support1EmailInput = () => {
      context.emit('dirty');
      support1EmailIssue.value = '';
    };

    const support2FirstNameInput = () => {
      context.emit('dirty');
      support2FirstNameIssue.value = '';
    };
    const support2LastNameInput = () => {
      context.emit('dirty');
      support2LastNameIssue.value = '';
    };
    const support2PhoneInput = () => {
      context.emit('dirty');
      support2PhoneIssue.value = '';
    };
    const support2EmailInput = () => {
      context.emit('dirty');
      support2EmailIssue.value = '';
    };

    // save changes to database
    const saveChanges = async () => {
      if (!validateFields()) return;

      const loader = loading.show({
        container: profileContainer.value,
        isFullPage: false,
      });

      await userApi.setProfileInfo(userType.value, profile.value);
      const redirect = originalEmail.value != profile.value.email;
      refresh();
      store.dispatch('updateFullname', profile.value);
      loader.hide();
      if (redirect) {
        router.push({ name: 'verify-email' });
      }
    };

    onMounted(async () => {
      const loader = loading.show({
        container: profileContainer.value,
        isFullPage: false,
      });

      patientId.value = store.getters.userId;
      userType.value = store.getters.userType;
      const response = await generalApi.timezones();
      timezones.value = response.data;
      loader.hide();
      await refresh();
    });

    const removePic = async () => {
      await userApi.resetProfilePic();
      router.go();
    };

    return {
      saveChanges,
      removePic,
      'profile-container': profileContainer,
      profile,
      timezones,
      profilePicSrc,
      patientId,
      userType,
      refresh,
      emailIssue,
      firstNameIssue,
      lastNameIssue,
      prefixIssue,
      phoneIssue,
      postalCodeIssue,
      certificationsIssue,
      addressIssue,
      cityIssue,
      doctorFirstNameIssue,
      doctorLastNameIssue,
      doctorEmailIssue,
      doctorPhoneIssue,
      support1FirstNameIssue,
      support1LastNameIssue,
      support1EmailIssue,
      support1PhoneIssue,
      support2FirstNameIssue,
      support2LastNameIssue,
      support2EmailIssue,
      support2PhoneIssue,
      is_admin_user,
      emailInput,
      firstNameInput,
      lastNameInput,
      prefixInput,
      phoneInput,
      postalCodeInput,
      certificationsInput,
      addressInput,
      cityInput,
      doctorFirstNameInput,
      doctorLastNameInput,
      doctorEmailInput,
      doctorPhoneInput,
      support1FirstNameInput,
      support1LastNameInput,
      support1EmailInput,
      support1PhoneInput,
      support2FirstNameInput,
      support2LastNameInput,
      support2EmailInput,
      support2PhoneInput,
    };
  },
};
</script>
